<template>
    <div class="signin-page">
        <basic-page>
            <template slot="left">
                <basic-page-left></basic-page-left>
            </template>
            <template slot="right">
                <div class="signin-content">
                    <div class="form-content">
                        <div :class="['width-full flex flex-justify-center fonts-15 cursor-pointer margin-b-15', type=='email'?'flex-row-reverse':'']">
                            <div :class="[type==='phone'?'color-666666':'color-link']" @click="type='phone'">{{$t('system.signup_page_form_title')}}</div>
                            <!-- <div :class="[type==='email'?'color-666666':'color-link']" @click="type='email'">邮箱注册</div> -->
                        </div>
						<el-form style="width: 100%">
							<el-form-item label="" style="margin-bottom: 15px;">
								<el-input :placeholder="$t('system.signup_page_form_phone_placeholder')" required clearable
                                    autocomplete="new"
                                    v-model="form.phone" 
                                    name="phone"
                                    @keydown.enter.native="(e) => submitSignup()">
                                    <el-select v-model="form.countryCode" filterable slot="prepend" placeholder="请选择" style="width: 85px;">
                                        <template v-for="(item, index) in countryCodeList">
                                            <el-option :label="`+${item.code}`" :value="item.code" :key="index">{{ `(+${item.code}) ${item.name}` }}</el-option>
                                        </template>
                                    </el-select>
                                </el-input>
							</el-form-item>
                            <el-form-item label="" style="margin-bottom: 15px;">
                                <div class="flex">
                                    <div class="margin-r-10">
                                        <el-input :placeholder="$t('system.signup_page_form_captcha_placeholder')" required clearable 
                                            autocomplete="new"
                                            v-model="form.captcha" 
                                            name="captcha"
                                            @keydown.enter.native="(e) => submitSignup()"
                                        ></el-input>
                                    </div>
                                    <div style="width: 120px;">
                                        <el-button round plain :disabled="hasSend" @click="getCaptcha" style="padding: 0;width:100%;height:40px;">{{hasSend?`${reSendTime}${$t('system.signup_page_form_send_captcha_seconds')}`:$t('system.signup_page_form_send_captcha')}}</el-button>
                                    </div>
                                </div>
							</el-form-item>
							<el-form-item label="" style="position: relative;margin-bottom: 15px;">
								<el-input :placeholder="$t('system.signup_page_form_password_placeholder')" :type="showPassword?'text':'password'" required 
									auto-complete="new-password"
                                    v-model="form.password"
									@keydown.enter.native="(e) => submitSignup()"
								></el-input>
								<div class="show-password" @click="setShowPassword">
									<svg-icon name="ib-open-eye" v-if="showPassword"></svg-icon>
                                    <svg-icon name="ib-close-eye" v-else></svg-icon>
								</div>
							</el-form-item>
                            <el-form-item label="" style="margin-bottom: 15px;">
                                <el-input :placeholder="$t('system.signup_page_form_invite_code_placeholder')" required clearable
                                    autocomplete="new"
                                    v-model="form.inviteCode" 
                                    name="phone"
                                    @keydown.enter.native="(e) => submitSignup()"
                                ></el-input>
							</el-form-item>
						</el-form>
                        <div class="width-full fonts-14 margin-b-20 flex flex-align-center flex-justify-between">
                            <div class="flex flex-align-center">
                                <div class="fonts-14 margin-l-5 line-height-1.25">
                                    {{$t('system.signup_page_form_clause_prefix')}}
                                    <a href="http://static.languagex.com/page/serviceTerms.html" target="_blank">{{$t('system.login_page_service_terms')}}</a>
                                    <a href="http://static.languagex.com/page/privacyPolicy.html" target="_blank" class="margin-l-5">{{$t('system.login_page_privacy_policy')}}</a>
                                </div>
                            </div>
                            <div style="width: 60px;text-align: right;"><a href="/login">{{$t('system.login_page_link_login')}}</a></div>
                        </div>
						<el-button type="primary margin-b-10" round @click="submitSignup" :loading="loading">{{$t('system.signup_page_form_button')}}</el-button>
					</div>
                </div>
            </template>
        </basic-page>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { Base64 } from "js-base64";
import BasicPage from './components/BasicPage';
import BasicPageLeft from './components/BasicPageLeft';
import tools from '@/utils/tools';
import { userApi } from '@/utils/api';
export default {
    components: { BasicPage, BasicPageLeft, },
    data() {
        return {
            type: 'phone', //email
            showPassword: false,
            loading: false,
            nextStep: false,
            form: {
                countryCode: '86',
                phone: '',
                captcha: '',
                password: '',
                email: '',
                isCheck: false,
                inviteCode: '',
            },
            hasSend:false,
            reSendTime:60,

            countryCodeList: [],

            sendTimer:null,
        }
    },
    computed:{
        ...mapGetters({
            isLogin:'user/loginStatus',
        }),
    },
    created(){
        if (this.isLogin){
            this.$router.replace('/');
        }else{
            this.form.inviteCode = this.$route.query.code || '';
        }
        this.countryCodeList = this.$t('common.country_code_list');
    },
    methods: {
        submitSignup(){
            let self = this;
            if(!this.form.phone){
                this.$message.error(this.$t('system.signup_page_form_verification_message_1'));
                return;
            }
            /* if(!tools.checkPhone(this.form.phone)){
                this.$message.error(this.$t('system.signup_page_form_verification_message_2'));
                return;
            } */
            if(!this.form.captcha){
                this.$message.error(this.$t('system.signup_page_form_verification_message_3'));
                return;
            }
            if(!this.form.password){
                this.$message.error(this.$t('system.signup_page_form_verification_message_4'));
                return;
            }
            let rst = /^(?=.*[a-z])(?=.*\d).{6,20}$/.test(this.form.password);
            if(!rst){
                this.$message.error(this.$t('system.signup_page_form_verification_message_5'));
                return;
            }
            let url = userApi.submitRegister;
            let postData = {
                regionCode: '+' + this.form.countryCode,
                account: this.form.phone,
                password: Base64.encode(this.form.password),
                captcha: this.form.captcha,
                coder: this.form.inviteCode,
            }
            this.loading = true;
            this.$ajax.post(url, postData).then(res=> {
                if(res.status == 200){
                    // console.log('token:::',this.$cookies.get('token'))
                    this.$store.dispatch("user/queryUserInfoAction", 'login');
                    this.$message.success(this.$t('system.signup_page_form_submit_success'));
                    setTimeout(()=>{
                        // self.$router.replace('/engine/config');
                        self.$router.go(0);
                    }, 2000);
                }
            }).finally(() => {
                this.loading = false;
            });
        },
        setShowPassword(){
            this.showPassword = !this.showPassword;
        },
        getCaptcha(){
            let self = this;
            if(!this.form.phone){
                this.$message.error(this.$t('system.signup_page_form_verification_message_1'));
                return;
            }
            /* if(!tools.checkPhone(this.form.phone)){
                this.$message.error(this.$t('system.signup_page_form_verification_message_2'));
                return;
            } */
            this.$ajax.post(userApi.sendCaptcha, {account: this.form.phone, regionCode: '+' + this.form.countryCode}).then(res=> {
                if(res.status === 200){
                    this.$message.success(this.$t('system.signup_page_form_sent_captcha_message'));
                    this.hasSend = true;
                    this.reSendTime = this.reSendTime - 1;
                    this.sendTimer=setInterval(()=> {
                        self.reSendTime = self.reSendTime - 1;
                        if(self.reSendTime < 0){
                            clearInterval(self.sendTimer);
                            self.reSendTime = 60;
                            self.hasSend = false;
                        }
                    }, 1000);
                }
            })
        },
        
    },
    mounted() {
        
    },
    destroyed() {
        clearInterval(this.sendTimer);
        this.sendTimer = null;
    }
}
</script>
<style lang="scss" scoped>
.signin-page{
    width: 100%;
	height: 100%;
    .signin-content {
        padding-top: 30px;
		width: 460px;
		height: 460px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}
}
</style>
